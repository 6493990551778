.lr-primary-button {
	@apply font-bold text-lr-bg p-2.5 rounded-md
		 bg-lr-green-primary border border-lr-green-secondary-1 transition-all text-center #{'!important'};

	&:hover:not(:disabled) {
		@apply bg-lr-green-secondary-1 #{'!important'};
	}

	&:active:not(:disabled) {
		@apply bg-lr-green-secondary-3 #{'!important'};
	}

	&:disabled {
		@apply border-gray-500 text-gray-500 bg-transparent #{'!important'};
	}
}

.lr-secondary-button {
	@apply font-bold text-lr-gray-primary p-2.5 rounded-md
		 bg-gray-50 border border-lr-gray-primary transition-all text-center #{'!important'};

	&:hover:not(:disabled) {
		@apply border-black bg-gray-200 #{'!important'};
	}

	&:active:not(:disabled) {
		@apply bg-gray-100 #{'!important'};
	}
}
