@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import '~react-image-gallery/styles/css/image-gallery.css';

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "styles/buttons.scss";

@import "~react-datepicker/dist/react-datepicker.css";


html {
        font-family: 'Montserrat';
        @apply bg-gray-100 text-lr-gray-primary;
}

.lr-container {
        @apply max-w-7xl px-3 mx-auto;
}

.lr-container-mobile {
        @extend .lr-container;
        @apply sm:px-3 px-0;
}

.lr-loader-container {
        @apply h-full w-full flex items-center justify-center;
}
