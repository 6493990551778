.cc-div-general {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.cc-div-offset-1 {
    height: 38%;
}

.cc-div-offset-2 {
    height: 43%;
}

.cc-div-offset-3 {
    height: 48%;
}

.cc-div-offset-4 {
    height: 53%;
}

.cc-units-switcher {
    position: absolute;
    left: 30px;
    top: -5px;
    z-index: 999999999;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
}
.cc-units-digits-label {
    position: absolute;
    left: 5px;
    top: -2px;
    z-index: 999999999;
    background-color: #fff;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14.3988px;
    line-height: 130%;
    color: #3F3F3F;
}

.cc-units-digits-proc {
    position: absolute;
    left: 68px;
    top: -2px;
    z-index: 999999999;
    background-color: #fff;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14.3988px;
    line-height: 130%;
    color: #3F3F3F;
}
.cc-units-loader {
    position: absolute;
    left: 90px;
    top: -5px;
    z-index: 999999999;
    background-color: #fff;
}

.cc-period-dates {
    position: absolute;
    left: 110px;
    top: -5px;
    z-index: 999999999;
    background-color: #fff;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    color: #3F3F3F;
    font-size: 14px;
}

.cc-close-button {
    background-color: #508104 !important;
    border-color: #508104 !important;
    border-radius: 10px 10px 0px 0px !important;
    margin: 1px !important;
    padding: 1px 3px 1px 3px !important;
}

.cc-close-button-div {
    border-radius: 10px 10px 0px 0px ;
    position: absolute;
    right: 13px;
    z-index: 1200;
    padding-top: 10px;
    transform: translateY(-37%); /* using the CSS3 Transform technique */
}

.cc-dates-button-div {
    border-radius: 10px 10px 0px 0px ;
    position: absolute;
    right: 160px;
    z-index: 1301;
    padding-top: 0px;
    transform: translateY(-37%); /* using the CSS3 Transform technique */
}

.cc-save-button-div {
    border-radius: 10px 10px 0px 0px ;
    position: absolute;
    right: 90px;
    z-index: 1301;
    padding-top: 0px;
    transform: translateY(-37%); /* using the CSS3 Transform technique */
}

.cc-aggregations-button-div {
    border-radius: 10px 10px 0px 0px ;
    position: absolute;
    right: 345px;
    z-index: 1301;
    padding-top: 0px;
    transform: translateY(-37%); /* using the CSS3 Transform technique */
}


.cc-show-live-data-div {
    border-radius: 10px 10px 0px 0px ;
    position: absolute;
    right: 480px;
    z-index: 1301;
    padding-top: 0px;
    transform: translateY(-37%); /* using the CSS3 Transform technique */
}

.cc-close-button-div-1 {
    bottom: 39%;
}

.cc-close-button-div-2 {
    bottom: 45%;
}

.cc-close-button-div-3 {
    bottom: 51%;
}

.cc-close-button-div-4 {
    bottom: 57%;
}

.cc-close-button-div-5 {
    bottom: 63%;
}

.cc-close-button-div-6 {
    bottom: 69%;
}

.cc-close-button-div-7 {
    bottom: 75%;
}

.cc-close-button-div-8 {
    bottom: 81%;
}

.cc-close-button-div-9 {
    bottom: 87%;
}

.cc-close-button-div-10 {
    bottom: 93%;
}


.agro2-button {
    background-color: #508104 !important;
    border-color: #508104 !important;
    border-radius: 10px 10px 10px 10px !important;
    margin: 1px !important;
    padding: 1px 3px 1px 3px !important;
}


.btn-date-period {
    width: 100% !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13.8845px;
    line-height: 17px;
    color: #3F3F3F;
    opacity: 0.8;
    cursor: pointer;
    padding: 1px 10px 1px 10px !important;
}

.btn-date-period:hover {
    text-decoration: underline;
}

.cc-toolip-dates {
    border-radius: 10px 10px 0px 0px !important;
    padding: 1px 5px 1px 5px;
    background-color: red;
    z-index: 1301 !important;
}

.modal {
    z-index: 999999999 !important;
}

.modal-ku {
    width: 90vw !important;    /* Occupy the 90% of the screen width */
    max-width: 90vw !important;
}

.chart-dialog {
    height: 220px;
}
