.device-info-container {
  z-index: 1300;
  position: absolute;
  background-color:white;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px 10px 20px;
  border-top-right-radius: 25px;
}

.device-info-container-mobile {
    height: 30%;
    width: 100%;
    bottom: 40%;
}

.device-info-container-mobile-0 {
    height: 40%;
    width: 100%;
    bottom: 0%;
}

.device-info-container-desktop {
    height: 100%;
    width: 30%;
    top: 0;
    left: 0;  
}

.close-icon {
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 10px;
    font-size: 130%;
}

.mpanel-close-icon {
    position: sticky;
    font-size: 130%;
    cursor: pointer;
    top: 0px;
    right: 0px;
    z-index: 999;

    width: 198%;
}


.back-icon {
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 20px;
    font-size: 130%;
}


.parameter-chooser-close-icon {
    cursor: pointer;
    font-size: 130%;
    display: inline;
    padding-bottom: 5px;
}

.parameter-chooser-close-col {
    text-align: end;
}

.header {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;

    color: #3F3F3F;
}

.header-device-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    color: #3F3F3F;
    text-align: center;
}

.map-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.spinner-div {
    width: 100%;
    text-align: center;
}

.es-layers-control {
    top: 100px;
}

.charts-div {
    z-index: 999 !important;
    height: 100%;
}


.device-charts-container {
  z-index: 999;
  height: 30%;
  width: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color:white;
  overflow: scroll;
  padding: 10px;
}

.device-charts-chooser-container {
  z-index: 998;
  height: 37%;
  width: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color:white;
  overflow: 'hidden';
  padding: 10px;
}

.data-chart-container {
  height: 100%;
}

.leaflet-div-icon {
    background: none!important;
    border: none!important;
}

.image-gallery-icon {
    padding: 0px;
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}

.devices-list-container {
  z-index: 991;
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0;
  right: 0;
  background-color:white;
  padding: 10px;
  border-radius: 25px 0px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.device-compares-container-full {
    left: 0;
    width: 100%;
}

.device-compares-container-50 {
    right: 0;
    width: 50%;
}

.device-compares-container-70 {
    right: 0;
    width: 70%;
}

.device-compares-container-66 {
    right: 0;
    width: 66%;
}


.device-compares-container {
  z-index: 999;
  height: 30%;
  position: absolute;
  bottom: 0;
  background-color:white;
  overflow: scroll;
  padding: 3px;
}

.device-compares-types-buttons {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: 5%;
  padding: 5px;
  margin-left: 20px;
  margin-top: -12px;
}


.device-compares-charts {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:white;
}


.device-compares-dates {
  z-index: 998;
  height: 31%;
  position: absolute;
  bottom: 0;
  background-color:white;
  overflow: 'hidden';
  padding-left: 10px;

}

.device-compares-chooser-general {
    position: absolute;
    bottom: 0;
    background-color:white;
    overflow: 'hidden';
    padding: 10px;
}


.device-compares-chooser-container-2 {
    z-index: 997;
    height: 39%;
}


.device-compares-chooser-container-3 {
    z-index: 996;
    height: 45%;
}


.device-compares-chooser-container-4 {
    z-index: 995;
    height: 51%;
}

.device-compares-chooser-container-5 {
    z-index: 994;
    height: 57%;
}

.device-compares-chooser-container-6 {
    z-index: 993;
    height: 63%;
}

.device-compares-chooser-container-7 {
    z-index: 992;
    height: 69%;
}

.device-compares-chooser-container-8 {
    z-index: 991;
    height: 75%;
}


.device-compares-chooser-container-9 {
    z-index: 990;
    height: 81%;
}


.device-compares-chooser-container-10 {
    z-index: 989;
    height: 87%;
}


.device-compares-chooser-container-11 {
    z-index: 988;
    height: 93%;
}

.device-compares-chooser-form {
    padding-top: 10px;
    padding-left: 3px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.chooser-period-label {
    font-size: .875rem;
}

.daterangepicker {
    z-index: 19999999;
}

.checkbox-ios {
	display: inline-block;    
	height: 28px;    
	line-height: 28px;  
	margin-right: 10px;      
	position: relative;
	vertical-align: middle;
	font-size: 14px;
	user-select: none;	
}
.checkbox-ios .checkbox-ios-switch {
	position: relative;	
	display: inline-block;
	box-sizing: border-box;			
	width: 56px;	
	height: 28px;
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: 25%/50%;	
	vertical-align: top;
	background: #eee;
	transition: .2s;
}
.checkbox-ios .checkbox-ios-switch:before {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;	
	display: inline-block;
	width: 24px;	
	height: 24px;
	border-radius: 50%;
	background: white;
	box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
	transition: .15s;
}
.checkbox-ios input[type=checkbox] {
	display: block;	
	width: 0;
	height: 0;	
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.checkbox-ios input[type=checkbox]:not(:disabled):active + .checkbox-ios-switch:before {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
}
.checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch {
	background: limegreen;
}
.checkbox-ios input[type=checkbox]:checked + .checkbox-ios-switch:before {
	transform:translateX(28px);
}
 
/* Hover */
.checkbox-ios input[type="checkbox"]:not(:disabled) + .checkbox-ios-switch {
	cursor: pointer;
	border-color: rgba(0, 0, 0, .3);
}
 
/* Disabled */
.checkbox-ios input[type=checkbox]:disabled + .checkbox-ios-switch {
	filter: grayscale(70%);
	border-color: rgba(0, 0, 0, .1);
}
.checkbox-ios input[type=checkbox]:disabled + .checkbox-ios-switch:before {
	background: #eee;
}
 
/* Focus */
.checkbox-ios.focused .checkbox-ios-switch:before {
	box-shadow: inset 0px 0px 4px #ff5623;
}

.mpanel-container-h58 {
  height: 60% !important;
}

.mpanel-container-h100 {
  height: 100% !important;
}

.mpanel-container-w50 {
    width: 50% !important;
}

.mpanel-container-w34 {
    width: 34% !important;
}

.mpanel-container-mobile {
    width: 100% !important;
    height: 50% !important;
    bottom: 0px;
}

.mpanel-container-desktop {
    top: 0;
    left: 0;  
}

.mpanel-container {
  z-index: 1001;
  position: absolute;
  background-color:white;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  border-top-right-radius: 25px;
  height: 100%;
}

.leaflet-verticalcenter {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 50%; /* possible because the placeholder's parent is the map */
    transform: translateY(-50%); /* using the CSS3 Transform technique */
    padding-top: 10px;
}

.leaflet-verticalcenter .leaflet-control {
    margin-bottom: 10px;
}

.btn-selected {
    background-color: #508104 !important;
    border-color: #508104 !important;
}

.btn-map {
    padding: 7px !important;
}

.btn-devices {
    background-color: #508104 !important;
    padding: 4px !important;
    border-color: #508104 !important;
    margin-right: 0 !important;
    border-radius: 10px 0px 0px 10px  !important;
}

.btn-mpanel {
    background-color: #508104 !important;
    padding: 4px !important;
    border-color: #508104 !important;
    margin-left: 0 !important;
    border-radius: 0px 10px 10px 0px  !important;
}

.devices-list-block-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 9.36532px;
    line-height: 11px;
    color: #3F3F3F;
    margin-bottom: -10px;
    opacity: 0.8;
    padding-top: 20px
}

.devices-list-device-type-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    color: #3F3F3F;
    padding: 7px 0px 0px 0px;
    cursor: pointer;
}

.devices-list-device-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 18px;
    color: #508104;
    cursor: pointer;
}

.devices-list-block {
    padding: 0px 0px 0px 15px;
}

.devices-list-icon-show {
    padding-top: 10px;
    cursor: pointer;
}

.di-type {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17.4848px;
    line-height: 21px;
    color: #3F3F3F;
}

.di-name {
    font-style: normal;
    font-weight: 300;
    font-size: 14.5707px;
    line-height: 18px;
    color: #3F3F3F;
}

.di-location {
    font-style: normal;
    font-weight: 300;
    font-size: 14.5707px;
    line-height: 18px;
    color: #508104;
}

.di-last-date {
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 12px;

/* Цвет 2 */

color: #3F3F3F;
}

.di-header {
    padding-top: 17px;
}

.di-data-block-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */


    /* Цвет 2 */

    color: #3F3F3F;
    padding-bottom: 10px;
}

.di-data-block {
    padding-top: 30px;
}

.di-cell-data {
    font-weight: 400;
    font-size: 17.9023px;
    line-height: 22px;
    color: #3F3F3F;
    padding-left: 7px;
    cursor: pointer;
}

.di-cell-units {
    font-weight: 400;
    font-size: 10.9023px;
    line-height: 22px;
/* identical to box height */


/* Цвет 2 */

    color: #3F3F3F;
    padding-left: 0px;
}

.di-cell-units-cels {
    font-weight: 400;
    font-size: 17.9023px;
    line-height: 22px;
/* identical to box height */


/* Цвет 2 */

    color: #3F3F3F;
    padding-left: 0px;
}


.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
}

.react-grid-item.react-grid-placeholder {
    background: #000;
    opacity: 0.1;
    transition-duration: 100ms;
    z-index: 2;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item:hover > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}


.update-icon {
    cursor: pointer;
    font-size: 140%;
    padding-left: 10px;
}

