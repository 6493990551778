.widget-row {
    padding-top: 15px;
}

.widget-col {
    padding-left: 15px;
}

.widget-div {
    background: #FFFFFF;
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    outline: #006837;
    outline-offset: -10px;
    outline-width: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.widget-div:hover {
    outline: solid #00683770 2px;
    outline-offset: -2px;
}

.widgets-div {
    padding: 15px;
}

.widget-header {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    padding-top: 15px;
    padding-left: 15px;
    cursor: pointer;
}


.widget-subheader {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 11px;
    color: #508104;
    padding: 15px;
    padding-top: 10px;
}

.msstation-widget-row-data {
    padding-left: 15px;
    padding-top: 10px;

}

.widget-data-indicator {
    font-size: 150%;
}

.widget-data-indicator-online {
    color: #508104;
}

.widget-data-indicator-offline {
    color: red;
}

.msstation-widget-row-data-temp {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    line-height: 31px;

    color: #3F3F3F;
}

.msstation-widget-row-data-hum {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #3F3F3F;
}

.msstation-widget-weather-div {
    width: 100%;
    padding: 10px;
}

.msstation-widget-weather-img {
    display: block;
    margin: 0 auto;
}

.camera-widget-img {
    margin-top: -20px;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.camera-widget-header {
    position: relative;
    top: 10px;
    left: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 9px;
    color: #FFFFFF;
}

.camera-widget-name {
    position: relative;
    top: 0px;
    left: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 9px;
    color: #FFFFFF;
    cursor: pointer;
    background-color: #000;
    padding: 2px;
    border-radius: 4px;
}

.camera-widget-name-bg {
    background-color: #000;
    padding: 3px;

}

.audio-widget-player {
    width: 98%;
    padding-left: 15px;
    padding-right: 15px;
}

audio::-webkit-media-controls-panel {
    background-color: #508104;
    color: #fff;
}

.camera-widget-div {
    padding-left: 15px;
}


.label-smart-eco {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 9px;
    color: #000000;
    padding: 5px 15px 5px 15px;
}

.widgets-grid {
    padding-top: 0px;
}

.ecosystem-row-data {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 9px;
    color: #000000;
    padding: 15px;
}

.ecosystem-right-data {
    text-align: right;
}

.ecosystem-center-data {
    text-align: center;
}

.ecosystem-devices-col-offset {
    padding-left: 7px !important;
    padding-top: 7px !important;
}

.city-parameter-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 7px;
    color: #3F3F3F;
}

.city-parameter-units {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 7px;
    color: #3F3F3F;
    height: 100%;
}

.city-parameter-units-lg {
    font-size: 19px !important;
}

.city-param-img {
    padding-bottom: 5px;
}

.soil-parameter-depth {
    padding: 5px 0px 5px 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 8.35457px;
    line-height: 5px;

    color: #3F3F3F;
}

.soil-parameter-temp {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 27px;
    color: #3F3F3F;
    padding: 5px 0px 5px 15px;
}

.soil-parameter-hum {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    color: #3F3F3F;
}

.ndvi-line {
    background: linear-gradient(90deg, #d73027, #fdae61, #fee08b, #ffffbf, #d9ef8b, #a6d96a, #66bd63, #1a9850, #006837);
    border-radius: 5%;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, .1);
    height: 20px;
    width: 100%;
}

.ndvi-line-wrapper .ndvi-icon {
    height: 1.7vw;
    position: absolute;
    top: -0.2vw;
    width: 1.7vw;
}

.ndvi-line-wrapper .ndvi-icon .dot {
    background-color: #424245;
    border: 0.2vw solid #fff;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.ndvi-line-wrapper {
    height: 5%;
    position: relative;
    width: 100%;
}