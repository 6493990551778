.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
    
.hidden { 
    display: none; 
}

.required-field {
    color: red;
}

.remove-resource-icon {
    cursor: pointer;
    font-size: 150%;
    color: red;
}

.device-data-icon {
    cursor: pointer;
    font-size: 150%;
    width: 80%;
}

.device-data-units {
    font-size: 80%;
    margin-right: 10px;
}

.edit-icon {
    cursor: pointer;
    font-size: 150%;
}

#mainNav {
    height: 75px;
    background-color: #3F3F3F;
}


::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-color: #ffffff00;
}

::-webkit-scrollbar-thumb {
    background-color: #508104;
}

.admin-section {
    padding-top: 75px;
}

.admin-back-button {
    font-size: 200%;
    display:table-cell;
    cursor: pointer;
}

.admin-header {
    display: table;
    padding-bottom: 15px;
}

.admin-header-text {
    display: table-cell;
    padding-left: 15px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
